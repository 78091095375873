@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.container {
  position: absolute;
  top: 1rem;
  z-index: 1;
  width: bpk-spacing-xxl();
  height: bpk-spacing-xxl();
  transform: rotate(90deg);
  margin-inline-start: -1.175rem;

  @include bpk-breakpoint-mobile {
    display: none;
  }

  :hover {
    background-color: $bpk-surface-highlight-day;
  }

  :focus-visible {
    box-shadow:
      0 0 0 (bpk-spacing-sm() / 2) $bpk-color-white,
      0 0 0 bpk-spacing-sm() $bpk-text-link-day;
  }
}

.swap-button {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: transform $bpk-duration-base;
  border: $bpk-border-size-xl solid $bpk-core-primary-day;
  border-radius: 50%;
  background-color: $bpk-color-white;

  .icon {
    max-width: bpk-spacing-base();
    max-height: bpk-spacing-base();
    outline-color: $bpk-color-white;
  }
}
